import { utils, writeFileXLSX, JSON2SheetOpts, WritingOptions } from 'xlsx';

function calculateMaxWidths(data) {
  const colWidths = [];

  data.forEach(row => {
    row.forEach((cell, colIndex) => {
      const cellValue = cell ? cell.toString() : '';
      const cellLength = cellValue.length;

      // Set the column width if this cell is the longest encountered so far
      if (!colWidths[colIndex] || colWidths[colIndex] < cellLength) {
        colWidths[colIndex] = cellLength;
      }
    });
  });

  // Adjust the column widths to a reasonable scale (e.g., scaling factor)
  return colWidths.map(width => ({ wch: width + 2 })); // Adding a small buffer for padding
}

/**
 * @param {object} param
 * @param {Array<{data: any[], options: JSON2SheetOpts}>} param.data
 * @param {string} param.fileName
 * @param {string[]} param.sheetNames
 * @param {WritingOptions} param.options
 * @param {boolean} param.protectSheet
 */
function jsonToExcel({
  data = [],
  fileName = 'download',
  sheetNames = [],
  options = {},
  protectSheet = false
}) {
  const wb = utils.book_new();

  for (let i = 0; i < data.length; i++) {
    const ws = utils.json_to_sheet(data[i].data, data[i]?.options || {});
    ws['!cols'] = calculateMaxWidths(data[i].data);

    if (protectSheet) {
      ws['!protect'] = {
        password: 'ul@pb1z',
        sheet: false,
        objects: true,
        scenarios: true
      };
    }

    utils.book_append_sheet(wb, ws, sheetNames?.[i] || 'Sheet' + (i + 1));
  }

  writeFileXLSX(wb, fileName + '.xlsx', options);
}

export default jsonToExcel;
